<template>
  <div :class="['p-CartId', { 'my': isMyCart }]">
    <a-spin :spinning="loading">
      <div class="header">
        <van-icon v-if="false" name="arrow-left" size="20" @click="$router.push(redirect)" />
        <center>{{ isMyCart ? '我的心愿单' : user.displayName + ' / 心愿单' }}</center>
        <span v-if="isMyCart" @click="share">分享</span>
      </div>
      <div class="block top">
        <span>共{{ datas.length }}个作品</span>
        <template v-if="isMyCart">
          <span class="pull-right" v-if="editing" @click="editing = false">完成</span>
          <span class="pull-right" v-else @click="editing = true">编辑</span>
        </template>
      </div>
      <div :class="['block', { editing }]" v-for="(data, $index) in datas" :key="$index">
        <van-swipe-cell
          :disabled="!editing"
          :before-close="({ instance }) => beforeClose({ opusId: data.opusId, instance })"
        >
          <a-checkbox
            v-if="editing"
            v-model="checked[$index]"
          />
          <card :props-data="Object.assign({}, data, { index: $index + 1 })" />
          <template #right>
            <van-button square text="移除" type="danger" class="delete-button" />
          </template>
        </van-swipe-cell>
      </div>
      <art-widget-logo style="padding-top: 32px; padding-bottom: 32px;" />
      <div
        class="bottom van-hairline--top-bottom van-tabbar van-tabbar--fixed"
        v-if="
          editing || (
            isMyCart &&
            user.roleInfo === 2 &&
            !editing &&
            datas.length > 0
          )
        "
      >
        <div class="bottom-wrap" v-if="editing">
          <a-checkbox :checked="checkedAll" @change="checkAll">全选</a-checkbox>
          <van-button type="info" @click="remove" size="small">移除</van-button>
        </div>
        <div class="bottom-wrap" v-if="
          isMyCart &&
          user.roleInfo === 2 &&
          !editing &&
          datas.length > 0
        ">
          <van-button type="info" @click="orderVisible = true" size="small">下单</van-button>
          <van-overlay class="overlay-order" :show="orderVisible">
            <div class="clearfix" @click.stop>
              <van-form @submit="order">
                <van-field
                  v-model="orderForm.collName"
                  name="collName"
                  label="收藏家姓名"
                  placeholder="请输入收藏家姓名"
                  :rules="[{ required: true, message: '请输入收藏家姓名' }]"
                  required
                />
                <van-field
                  v-model="orderForm.collPhone"
                  name="collPhone"
                  type="number"
                  label="电话"
                  placeholder="请输入电话"
                  :rules="[
                    { required: true, message: '请输入电话' },
                    { validator: phoneValidator, message: '输入电话格式有误' }
                  ]"
                  required
                />
                <van-field
                  v-model="orderForm.collWorkType"
                  name="collWorkType"
                  label="职业"
                  placeholder="请输入职业"
                  :rules="[{ required: true, message: '请输入职业' }]"
                  required
                />
                <van-field
                  v-model="orderForm.collTradeLocation"
                  name="collTradeLocation"
                  type="textarea"
                  rows="3"
                  label="交易地址"
                  placeholder="请输入艺术品交易地址"
                  :rules="[{ required: true, message: '请输入艺术品交易地址' }]"
                  required
                />
                <van-field
                  v-model="orderForm.collTradeAmount"
                  name="collTradeAmount"
                  type="number"
                  label="成交金额"
                  placeholder="请输入成交金额"
                  :rules="[{ required: true, message: '请输入成交金额' }]"
                  required
                />
                <van-cell
                  title="是否需要安装服务"
                  center
                >
                  <template #right-icon>
                    <van-switch v-model="orderForm.collIsDress" size="16" />
                  </template>
                </van-cell>
                <van-cell
                  title="是否需要安排线下艺术顾问"
                  center
                >
                  <template #right-icon>
                    <van-switch v-model="orderForm.collLiveView" size="16" />
                  </template>
                </van-cell>
                <van-button class="pull-right" type="info" size="small">确&nbsp;定</van-button>
                <van-button class="pull-right" type="info" native-type="button" @click="orderVisible = false" plain size="small">取&nbsp;消</van-button>
              </van-form>
            </div>
          </van-overlay>
        </div>
      </div>
    </a-spin>
    <art-widget-share v-model="shareVisible" :share-options="shareOptions" />
  </div>
</template>

<script>
import request from '@/api/request'
import store from '@/store'

const Card = {
  functional: true,
  render (h, {
    props: {
      propsData: {
        opusId,
        imgUrl,
        opusTitle,
        opusCode,
        userName,
        index,
        opusType,
        width,
        height,
        years,
      }
    },
    parent
  }) {
    const Image = h('van-image', {
      props: {
        width: 120,
        height: 90,
        src: `${imgUrl}!art_thumb` || '',
        errorIcon: 'photo-o',
        lazyLoad: true
      }
    })

    const $index = ('0' + index).slice(-2)
    const Index = h('span', {
      class: ['idx']
    }, $index)

    const subTitle = [
      opusType,
      `${width}x${height}cm`
    ]

    if (years > 0) {
      subTitle.push(`${years}`)
    }

    const Intro = h('div', {
      class: ['intro']
    }, [
      h('h4', opusTitle),
      h('p', subTitle.join(' / ')),
      h('p', '作品编号 ' + opusCode),
      h('p', { style: { marginBottom: 0 } }, '# ' + userName),
      Index
    ])

    return h('div', {
      class: ['card'],
      on: {
        click: () => parent.go({
          name: 'art',
          params: { id: opusId }
        })
      }
    }, [
      Image,
      Intro
    ])
  }
}

export default {
  data () {
    return {
      datas: [],
      isMyCart: false,
      user: {
        displayName: ''
      },
      loading: false,
      editing: false,
      checked: [],
      checkedAll: false,
      redirect: window.decodeURIComponent(
        this.$route.query.redirect || '/'
      ),
      shareVisible: false,
      shareOptions: {},
      // order
      orderVisible: false,
      orderForm: {
        collName: '',
        collPhone: '',
        collWorkType: '',
        collTradeLocation: '',
        collTradeAmount: '',
        collIsDress: 0,
        collLiveView: 0
      }
    }
  },
  watch: {
    checked (val) {
      this.checkedAll = val.every(o => Boolean(o))
    }
  },
  methods: {
    phoneValidator (v) {
      return /^1[3456789]\d{9}$/.test(v)
    },
    go (to) {
      if (!this.editing) {
        this.$router.push(to)
      }
    },
    share () {
      if (
        this.isMyCart &&
        this.shareOptions.URL === undefined
      ) {
        return request.getCartId().then(({ shareId }) => {
          this.shareOptions = {
            URL: `${window.location.origin}/cart/${shareId}`,
            TITLE: this.user.displayName + '的心愿单'
          }

          this.shareVisible = true
        })
      }

      this.shareVisible = true
    },
    checkAll () {
      this.checked = Array.from({
        length: this.datas.length,
      }, () => !this.checkedAll)
    },
    beforeClose ({ opusId, instance }) {
      opusId && request.removeFromCart({
        opusId,
      }).then(() => {
        this.fetchData()
        instance.close()
      })
    },
    remove () {
      const toRemoved = []

      for (let i = 0; i < this.checked.length; i += 1) {
        if (this.checked[i]) {
          const o = this.datas[i]

          if (o && o.opusId) {
            toRemoved.push(o.opusId)
          }
        }
      }

      if (toRemoved.length) {
        this.$dialog.confirm({
          title: '移除',
          message: '确定移除这' + toRemoved.length + '项'
        })
          .then(() => {
            request.removeFromCart({
              opusId: toRemoved.join(',')
            }).then(() => {
              this.checked = []
              this.fetchData()
            })
          })
          .catch(() => {})
      }
    },
    order (values) {
      if (this.datas.length === 0) {
        return
      }

      const {
        collIsDress,
        collLiveView
      } = this.orderForm

      const opusId = this.datas.map(({ opusId }) => opusId).join(',')

      request.order({
        opusId,
        collIsDress: Number(collIsDress),
        collLiveView: Number(collLiveView),
        ...values
      }).then(() => {
        this.$notify({
          type: 'success',
          message: '下单成功，我们的服务人员将尽快联系您'
        })

        request.removeFromCart({ opusId }).then(() => {
          this.fetchData()
          this.orderVisible = false
        })
      })
    }
  },
  components: { Card },
  beforeRouteEnter (to, from, next) {
    const { params: { id } } = to

    next(vm => {
      vm.isMyCart = id === undefined
      vm.fetchData = () => {
        if (vm.isMyCart) {
          return request.getMyCart().then(({ opus }) => {
            vm.datas = opus || []
          })
        }

        request.getCartById({ shareId: id }).then(({
          opus,
          shareUserName,
          shareUserId
        }) => {
          vm.user = {
            displayName: shareUserName,
            id: shareUserId
          }
          vm.loading = false
          vm.datas = opus || []
        })
      }

      if (vm.isMyCart) {
        return store.dispatch('GetInfo', userInfo => {
          vm.user = userInfo
          vm.loading = false
          vm.fetchData()
        })
      }

      vm.loading = true
      vm.fetchData()
    })
  }
}
</script>

<style lang="less">
.p-CartId {
  background-color: #F7F8FA;
  min-height: 100vh;

  .header {

    center + span {
      position: absolute;
      right: 20px;
      top: 15px;
      font-size: 14px;
      line-height: 24px;
      color: #888;
    }
  }

  .ant-checkbox-inner {
    border-radius: 500px;
  }

  .ant-checkbox-checked::after {
    border-radius: 500px;
  }

  .van-swipe-cell__right .van-button {
    height: 100%;
  }

  .block {
    background-color: #fff;
    margin-bottom: 2px;

    .ant-checkbox-wrapper {
      width: 30px;
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -15px;

      & + .card {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
      }
    }

    &.editing .card {
      padding-left: 50px;
    }

    &.top {
      padding: 0 20px;
      margin-bottom: 4px;

      > span {
        color: #a8a8a8;
        font-size: 13px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 36px;
        vertical-align: top;
      }
    }
  }

  .bottom {
    z-index: 99;

    &-wrap {
      width: 100%;
      padding: 15px 20px;

      .ant-checkbox-wrapper {
        margin-top: 5px;
      }

      .van-button {
        float: right;
      }
    }
  }

  .card {
    position: relative;
    font-size: 0;
    padding: 15px 20px;

    .van-image {
      border-radius: 4px;
      overflow: hidden;
    }

    .intro {
      width: calc(100% - 120px);
      padding-left: 20px;
      float: right;
      position: relative;

      h4 {
        font-size: 14px;
        color: @primary-color;
      }

      p {
        color: @primary-color-grey;
        font-size: 12px;
        margin-bottom: 5px;

        & > span:not(:last-child):after {
          content: '/';
          display: inline-block;
          width: 1em;
          text-align: center;
        }
      }

      span {
        font-size: 12px;
      }
    }

    .idx {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 16px;
      line-height: 24px;
      color: #b4b4b4;
      background-color: #F7F8FA;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      text-align: center;
    }
  }
}

.my {

  .block.top span {
    width: initial;
  }
}

.overlay-order {
  text-align: center;

  &:before {
    content: '';
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .clearfix {
    width: 80%;
    padding: 20px 10px;
    text-align: left;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;

    .van-button {
      margin-top: 20px;
      margin-left: 10px;
    }
  }
}
</style>